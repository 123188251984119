import React from "react"
//components
import Update from "../components/Query/Info/Update"
import Section from "../components/Section"
//css
import "../css/bottom.css"
const Bottom = ({ pageContext }) => {
  return (
    <Section classname="bottom" container>
      <Update pageContext={pageContext} />
    </Section>
  )
}

export default Bottom
