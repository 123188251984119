import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
//component
import Section from "../components/Section"
import ArgumentListFr from "../components/Query/Argument/ArgumentListFr"
import ArgumentListNl from "../components/Query/Argument/ArgumentListNl"
//css
import "../css/argumentList.css"
//data
const getData = graphql`
  {
    c: contentfulConfig {
      argumentsBackground {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`

const Arguments = ({ pageContext }) => {
  const data = useStaticQuery(getData)
  return (
    <Section classname="argument">
      <BackgroundImage fluid={data.c.argumentsBackground.fluid}>
        {pageContext.node_locale === "fr" || pageContext.langKey === "fr" ? (
          <ArgumentListFr />
        ) : (
          <ArgumentListNl />
        )}
      </BackgroundImage>
    </Section>
  )
}

export default Arguments
