import React from "react"
//components
import Section from "../components/Section"
import SlideShowFr from "../components/Query/Header/SlideShowFr"
import SlideShowNl from "../components/Query/Header/SlideShowNl"
import BreadcrumbTitlePage from "../components/Query/Header/BreadcrumbTitlePage"
import BreadcrumbTitleService from "../components/Query/Header/BreadcrumbTitleService"
//css
import "../css/breadcrumbTitle.css"
import "../css/contactBlock.css"
const Header = ({ pageContext, location }) => {
  return (
    <Section classname="header">
      {pageContext.langKey === "fr" || location.pathname === "/fr/" ? (
        <SlideShowFr pageContext={pageContext} />
      ) : pageContext.langKey === "nl" || location.pathname === "/nl/" ? (
        <SlideShowNl pageContext={pageContext} />
      ) : pageContext.type === "service" ? (
        <BreadcrumbTitleService pageContext={pageContext} />
      ) : (
        <BreadcrumbTitlePage pageContext={pageContext} />
      )}
    </Section>
  )
}

export default Header
