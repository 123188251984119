import React from "react"
import { Link } from "gatsby"
const CustomLink = ({ to, children, classname }) => {
  return (
    <>
      {classname ? (
        <Link
          to={to}
          className={`${classname}`}
          activeClassName="is-active"
          partiallyActive={true}
        >
          {children}
        </Link>
      ) : (
        <Link to={to} activeClassName="is-active" partiallyActive={true}>
          {children}
        </Link>
      )}
    </>
  )
}

export default CustomLink
