import React from "react"
//components
import Section from "../components/Section"
import FooterFirstFr from "../components/Query/Footer/FooterFirstFr"
import FooterFirstNl from "../components/Query/Footer/FooterFirstNl"
import FooterSecondFr from "../components/Query/Footer/FooterSecondFr"
import FooterThirdFr from "../components/Query/Footer/FooterThirdFr"
import FooterSecondNl from "../components/Query/Footer/FooterSecondNl"
import FooterThirdNl from "../components/Query/Footer/FooterThirdNl"
//css
import "../css/footer.css"
const Footer = ({ pageContext }) => {
  return (
    <Section classname="footer" container flex>
      {pageContext.node_locale === "fr" || pageContext.langKey === "fr" ? (
        <>
          <FooterFirstFr pageContext={pageContext} />
          <FooterSecondFr />
          <FooterThirdFr />
        </>
      ) : (
        <>
          <FooterFirstNl pageContext={pageContext} />
          <FooterSecondNl />
          <FooterThirdNl />
        </>
      )}
    </Section>
  )
}

export default Footer
