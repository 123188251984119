import React from "react"
import { graphql, useStaticQuery } from "gatsby"
//components
import CustomLink from "../Link/CustomLink"
//data
const getData = graphql`
  {
    fr: contentfulInfo(node_locale: { eq: "fr" }) {
      siteLogo {
        svg {
          content
        }
      }
      siteSlogan
      node_locale
    }
    nl: contentfulInfo(node_locale: { eq: "nl" }) {
      siteLogo {
        svg {
          content
        }
      }
      siteSlogan
      node_locale
    }
  }
`
const Logo = ({ pageContext }) => {
  const data = useStaticQuery(getData)
  return (
    <div className="logo">
      {pageContext.node_locale === "fr" || pageContext.langKey === "fr" ? (
        <CustomLink to={`/${data.fr.node_locale}/`}>
          <div
            className="logo icon"
            dangerouslySetInnerHTML={{
              __html: data.fr.siteLogo.svg.content,
            }}
          />
          <span>{data.fr.siteSlogan}</span>
        </CustomLink>
      ) : (
        <CustomLink to={`/${data.nl.node_locale}/`}>
          <div
            className="logo icon"
            dangerouslySetInnerHTML={{
              __html: data.nl.siteLogo.svg.content,
            }}
          />
          <span>{data.nl.siteSlogan}</span>
        </CustomLink>
      )}
    </div>
  )
}

export default Logo
