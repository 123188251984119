import React from "react"
import { graphql, useStaticQuery } from "gatsby"
//data
const getData = graphql`
  {
    c: contentfulInfo(node_locale: { eq: "nl" }) {
      phone1Link
      phone1Subtitle
      phone1Title
      phone1Icon {
        svg {
          content
        }
      }
    }
  }
`
const PhoneFirst = () => {
  const data = useStaticQuery(getData)
  return (
    <div className="phoneFirst">
      <div
        className="icon"
        dangerouslySetInnerHTML={{ __html: data.c.phone1Icon.svg.content }}
      />
      <div className="content">
        <h3>{data.c.phone1Subtitle}</h3>
        <p>
          <a href={data.c.phone1Link}>{data.c.phone1Title}</a>
        </p>
      </div>
    </div>
  )
}

export default PhoneFirst
