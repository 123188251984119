import React from "react"
import { graphql, useStaticQuery } from "gatsby"
//components
import CustomLink from "../Link/CustomLink"
//data
const getData = graphql`
  {
    c: allContentfulPage(
      filter: { menu: { eq: "infoMenu" }, node_locale: { eq: "fr" } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        title
        slug
        node_locale
      }
    }
  }
`
const MenuInfo = ({ title }) => {
  const data = useStaticQuery(getData)
  return (
    <div className="menuInfo">
      {title ? <h2>{title}</h2> : ""}
      <ul>
        {data.c.nodes.map((item, index) => {
          return (
            <li key={index}>
              <CustomLink to={`/${item.node_locale}${item.slug}`}>
                {item.title}
              </CustomLink>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default MenuInfo
