import React from "react"
//components
import Logo from "../Info/Logo"
import PhoneFirstNl from "../Info/PhoneFirstNl"
import TvaNl from "../Info/TvaNl"
import ContactOnlineNl from "../Info/ContactOnlineNl"
const FooterFirst = ({ pageContext }) => {
  return (
    <div className="footerFirst">
      <Logo pageContext={pageContext} />
      <div className="blockFooter">
        <TvaNl />
        <PhoneFirstNl />
        <ContactOnlineNl />
      </div>
    </div>
  )
}

export default FooterFirst
