import React from "react"
import { graphql, useStaticQuery } from "gatsby"
//components
import CustomLink from "../Link/CustomLink"
//data
const getData = graphql`
  {
    c: contentfulConfig(node_locale: { eq: "nl" }) {
      node_locale
      homeRealTitle
      homeRealDesc {
        homeRealDesc
      }
      homeRealButtonTitle
      homeRealButtonLink
    }
  }
`

const HomeReal = () => {
  const data = useStaticQuery(getData)
  return (
    <div className="homeReal">
      <h2>{data.c.homeRealTitle}</h2>
      <p>{data.c.homeRealDesc.homeRealDesc}</p>
      <CustomLink
        classname="button"
        to={`/${data.c.node_locale}${data.c.homeRealButtonLink}`}
      >
        {data.c.homeRealButtonTitle}
      </CustomLink>
    </div>
  )
}

export default HomeReal
