import React from "react"
import { graphql, useStaticQuery } from "gatsby"
//data
const getData = graphql`
  {
    c: contentfulInfo(node_locale: { eq: "fr" }) {
      tvaNumber
      tvaTitle
      tvaIcon {
        svg {
          content
        }
      }
    }
  }
`
const Tva = () => {
  const data = useStaticQuery(getData)
  return (
    <div className="tva">
      <div
        className="icon"
        dangerouslySetInnerHTML={{ __html: data.c.tvaIcon.svg.content }}
      />
      <div className="content">
        <h3>{data.c.tvaTitle}</h3>
        <p>{data.c.tvaNumber}</p>
      </div>
    </div>
  )
}

export default Tva
