import React from "react"
import { graphql, useStaticQuery } from "gatsby"
//components
import CustomLink from "../Link/CustomLink"
import Section from "../../Section"
//css
import "../../../css/topDevis.css"
//data
const getData = graphql`
  {
    c: contentfulConfig(node_locale: { eq: "fr" }) {
      topDevisLink
      topDevisText
      node_locale
    }
  }
`
const TopDevis = () => {
  const data = useStaticQuery(getData)
  return (
    <Section classname="topDevis" container>
      <CustomLink to={`/${data.c.node_locale}${data.c.topDevisLink}`}>
        {data.c.topDevisText}
      </CustomLink>
    </Section>
  )
}

export default TopDevis
