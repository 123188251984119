import React from "react"
import { graphql, useStaticQuery } from "gatsby"
//components
import MenuService from "../Menu/MenuServicesFr"
import Social from "../Social/Social"
//data
const getData = graphql`
  {
    c: contentfulConfig(node_locale: { eq: "fr" }) {
      footerServiceMenuTitle
      footerSocialTitle
      socialActive
    }
  }
`
const FooterThird = () => {
  const data = useStaticQuery(getData)
  return (
    <div className="footerThird">
      <MenuService title={data.c.footerServiceMenuTitle} />
      {data.c.socialActive ? <Social title={data.c.footerSocialTitle} /> : ""}
    </div>
  )
}

export default FooterThird
