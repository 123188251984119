import React from "react"
//components
import Section from "../components/Section"
import HomeRealFr from "../components/Query/Realisation/HomeRealFr"
import HomeRealNl from "../components/Query/Realisation/HomeRealNl"
import HomeGalery from "../components/Query/Realisation/HomeGalery"
//css
import "../css/homeReal.css"
const Realisations = ({ pageContext }) => {
  return (
    <Section classname="realisation" flex container>
      {pageContext.langKey === "fr" ? <HomeRealFr /> : <HomeRealNl />}
      <HomeGalery />
    </Section>
  )
}

export default Realisations
