import React from "react"
import { graphql, useStaticQuery } from "gatsby"

//components
import CustomLink from "../Link/CustomLink"
import Section from "../../Section"
import ContactBlockPage from "../Info/ContactBlockPage"

//data
const getData = graphql`
  {
    c: allContentfulPage {
      nodes {
        contentful_id
        node_locale
        mainImage {
          fluid {
            ...GatsbyContentfulFluid_noBase64
            src
          }
        }
      }
    }
    fr: contentfulConfig(node_locale: { eq: "fr" }) {
      breadcrumbHome
    }
    nl: contentfulConfig(node_locale: { eq: "nl" }) {
      breadcrumbHome
    }
  }
`

const BreadcrumbTitle = ({ pageContext }) => {
  const data = useStaticQuery(getData)

  let t = pageContext.location.pathname.split("/")
  t.splice(0, 1)
  let n = []
  const r = t.length

  for (let i = 0; i < r; i++) {
    i - 1 ? (n[i] = "/" + t[i]) : (n[i] = "/" + t[i - 1] + "/" + t[i])
    t[i] = t[i].split("-").join(" ")
  }

  pageContext.node_locale === "fr"
    ? (t[0] = data.fr.breadcrumbHome)
    : (t[0] = data.nl.breadcrumbHome)

  return (
    <>
      {data.c.nodes.map((item, index) => {
        if (
          pageContext.contentful_id === item.contentful_id &&
          pageContext.node_locale === item.node_locale
        ) {
          return (
            <div key={index} className="headerBg">
              <div
                className="bg"
                style={{ background: "url(" + item.mainImage.fluid.src + ")" }}
              >
                <div className="container flex">
                  <Section classname="breadcrumbTitle">
                    <h1>{pageContext.title}</h1>
                    <ul className="breadcrumb">
                      {n.map((item, index) => {
                        return (
                          <li key={index}>
                            <CustomLink to={n[index]}>{t[index]}</CustomLink>
                          </li>
                        )
                      })}
                    </ul>
                  </Section>
                  <ContactBlockPage pageContext={pageContext} />
                </div>
              </div>
            </div>
          )
        } else {
          return false
        }
      })}
    </>
  )
}

export default BreadcrumbTitle
