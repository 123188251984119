import React from "react"
//components
import Section from "../components/Section"
import Logo from "../components/Query/Info/Logo"
import MenuMainFr from "../components/Query/Menu/MenuMainFr"
import MenuMainNl from "../components/Query/Menu/MenuMainNl"
import MenuMobileFr from "../components/Query/Menu/MenuMobileFr"
import MenuMobileNl from "../components/Query/Menu/MenuMobileNl"
//css
import "../css/branding.css"
const Branding = ({ pageContext }) => {
  return (
    <Section classname="branding" flex container>
      <Logo pageContext={pageContext} />
      {pageContext.node_locale === "fr" || pageContext.langKey === "fr" ? (
        <MenuMainFr />
      ) : (
        <MenuMainNl />
      )}

      {pageContext.node_locale === "fr" || pageContext.langKey === "fr" ? (
        <MenuMobileFr />
      ) : (
        <MenuMobileNl />
      )}
    </Section>
  )
}

export default Branding
