import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
//component
import MenuMain from "../Menu/MenuMainFr"
import MenuService from "../Menu/MenuServicesFr"
import MenuInfo from "../Menu/MenuInfoFr"
import PhoneFirst from "../Info/PhoneFirstFr"
import ContactOnline from "../Info/ContactOnlineFr"
import Devis from "../Info/DevisFr"
//css
import "../../../css/mobile.css"
//data
const getData = graphql`
  {
    c: contentfulConfig(node_locale: { eq: "fr" }) {
      footerInfoMenuTitle
      footerMainMenuTitle
      footerServiceMenuTitle
    }
  }
`
const MenuMobile = () => {
  const data = useStaticQuery(getData)
  const [mobile, setMobile] = useState()

  const mobileToggle = () => {
    setMobile(mobile => !mobile)

    mobile
      ? document.body.classList.remove("active")
      : document.body.classList.add("active")
  }

  return (
    <>
      <div
        className="mobileNav"
        role="button"
        tabIndex={0}
        onClick={() => mobileToggle()}
        onKeyDown={() => mobileToggle()}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div
        className={mobile ? `menuMobile active` : `menuMobile`}
        role="button"
        tabIndex={0}
        onClick={() => mobileToggle()}
        onKeyDown={() => mobileToggle()}
      >
        <div className="container">
          <div className="mobileNav">
            <span></span>
            <span></span>
          </div>
          <h2>{data.c.footerMainMenuTitle}</h2>
          <MenuMain />
          <h2>{data.c.footerServiceMenuTitle}</h2>
          <MenuService />
          <h2>{data.c.footerInfoMenuTitle}</h2>
          <MenuInfo />
          <h2>Contact</h2>
          <PhoneFirst />
          <ContactOnline />
          <Devis />
        </div>
      </div>
    </>
  )
}

export default MenuMobile
