import React from "react"
import { graphql, useStaticQuery } from "gatsby"
//component
import Section from "../../Section"
//data
const getData = graphql`
  {
    c: allContentfulArgument(
      filter: { node_locale: { eq: "fr" } }
      sort: { fields: order, order: ASC }
      limit: 3
    ) {
      nodes {
        icon {
          svg {
            content
          }
        }
        title
        desc {
          desc
        }
      }
    }
  }
`
const ArgumentList = () => {
  const data = useStaticQuery(getData)
  return (
    <Section classname="argList" container flex>
      {data.c.nodes.map((item, index) => {
        return (
          <div key={index} className="arg">
            <div
              className="icon"
              dangerouslySetInnerHTML={{ __html: item.icon.svg.content }}
            />
            <h3>{item.title}</h3>
            <p>{item.desc.desc}</p>
          </div>
        )
      })}
    </Section>
  )
}

export default ArgumentList
