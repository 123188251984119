import React from "react"
import { graphql, useStaticQuery } from "gatsby"
//components
import CustomLink from "../Link/CustomLink"
//data
const getData = graphql`
  {
    c: allContentfulInfo {
      nodes {
        siteUrl
        siteTitle
        node_locale
      }
    }
    u: contentfulUpdate {
      update(formatString: "YYYY")
    }
  }
`
const Update = ({ pageContext }) => {
  const data = useStaticQuery(getData)
  return (
    <div>
      <span>{data.u.update}</span>
      {data.c.nodes.map((item, index) => {
        if (
          pageContext.node_locale === item.node_locale ||
          pageContext.langKey === item.node_locale
        ) {
          return (
            <CustomLink key={index} to={`/${item.node_locale}/`}>
              {item.siteTitle}
            </CustomLink>
          )
        } else {
          return false
        }
      })}
    </div>
  )
}

export default Update
