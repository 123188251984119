import React from "react"
import { graphql, useStaticQuery } from "gatsby"

//components

//import Top from "../components/Top"
import TopDevisFr from "../components/Query/Top/TopDevisFr"
import TopDevisNl from "../components/Query/Top/TopDevisNl"
import Branding from "../components/Branding"
import Header from "../components/Header"

import Section from "../components/Section"
import Arguments from "../components/Arguments"
import Realisations from "../components/Realisations"
import Footer from "../components/Footer"
import Bottom from "../components/Bottom"

//css
import "../css/global.css"
import "../css/gird.css"

//data
const getData = graphql`
  {
    c: contentfulConfig {
      realHome
    }
  }
`

const Layout = ({ children, pageContext, location }) => {
  const data = useStaticQuery(getData)
  pageContext.location = location
  return (
    <>
      <div
        className={`layout ${
          location.pathname === "/fr/" || location.pathname === "/nl/"
            ? "front"
            : "not-front"
        } page-type-${pageContext.type}`}
      >
        {pageContext.langKey === "fr" || pageContext.node_locale === "fr" ? (
          <TopDevisFr />
        ) : (
          <TopDevisNl />
        )}
        <Branding pageContext={pageContext} />
        <Header pageContext={pageContext} location={location} />

        <Section classname="main">{children}</Section>
        <Arguments pageContext={pageContext} />
        {pageContext.langKey ? (
          data.c.realHome ? (
            <Realisations pageContext={pageContext} />
          ) : (
            ""
          )
        ) : (
          ""
        )}

        <Footer pageContext={pageContext} />
        <Bottom pageContext={pageContext} />
      </div>
    </>
  )
}

export default Layout
