import React from "react"
import { graphql, useStaticQuery } from "gatsby"
//components
import MenuMain from "../Menu/MenuMainNl"
import MenuInfo from "../Menu/MenuInfoNl"
//data
const getData = graphql`
  {
    c: contentfulConfig(node_locale: { eq: "nl" }) {
      footerInfoMenuTitle
      footerMainMenuTitle
    }
  }
`
const FooterSecond = () => {
  const data = useStaticQuery(getData)
  return (
    <div className="footerSecond">
      <MenuMain title={data.c.footerMainMenuTitle} />
      <MenuInfo title={data.c.footerInfoMenuTitle} />
    </div>
  )
}

export default FooterSecond
