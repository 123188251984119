import React from "react"
//components
import Logo from "../Info/Logo"
import PhoneFirstFr from "../Info/PhoneFirstFr"
import TvaFr from "../Info/TvaFr"
import ContactOnlineFr from "../Info/ContactOnlineFr"
const FooterFirst = ({ pageContext }) => {
  return (
    <div className="footerFirst">
      <Logo pageContext={pageContext} />
      <div className="blockFooter">
        <TvaFr />
        <PhoneFirstFr />
        <ContactOnlineFr />
      </div>
    </div>
  )
}

export default FooterFirst
