import React, { useState, useRef, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
//data
const getData = graphql`
  {
    c: allContentfulRealisation(limit: 5) {
      nodes {
        image {
          fixed(width: 500, height: 300) {
            ...GatsbyContentfulFixed
          }
        }
      }
    }
  }
`
const HomeGalery = () => {
  const data = useStaticQuery(getData)

  const [slide, setSlide] = useState(0)
  const [counter, setCounter] = useState(0)

  const r = useRef(null)
  r.current = { counter, setCounter }
  const nbr = data.c.nodes.length - 1

  useEffect(() => {
    if (slide > nbr) {
      r.current.setCounter((r.current.counter = 0))
      setSlide(slide => 0)
    } else if (slide < 0) {
      r.current.setCounter((r.current.counter = nbr))
      setSlide(slide => nbr)
    } else {
      r.current.setCounter((r.current.counter = slide))
    }
  }, [slide, nbr])

  return (
    <div className="galeryHome">
      <div className="galeryHomeNav">
        <div
          onClick={() => {
            setSlide(slide - 1)
          }}
          onKeyDown={() => {
            setSlide(slide - 1)
          }}
          role="button"
          tabIndex={0}
        >
          ←
        </div>
        <div
          onClick={() => {
            setSlide(slide + 1)
          }}
          onKeyDown={() => {
            setSlide(slide + 1)
          }}
          role="button"
          tabIndex={0}
        >
          →
        </div>
      </div>
      <Image fixed={data.c.nodes[r.current.counter].image.fixed} />
    </div>
  )
}

export default HomeGalery
