import React from "react"
import { graphql, useStaticQuery } from "gatsby"
//components
import CustomLink from "../Link/CustomLink"
//data
const getData = graphql`
  {
    c: contentfulInfo(node_locale: { eq: "fr" }) {
      devisIcon {
        svg {
          content
        }
      }
      devisTitle
      devisLink
      node_locale
    }
  }
`
const Devis = () => {
  const data = useStaticQuery(getData)
  return (
    <div className="devis">
      <div
        className="icon"
        dangerouslySetInnerHTML={{ __html: data.c.devisIcon.svg.content }}
      />
      <div className="content">
        <p>
          <CustomLink to={`/${data.c.node_locale}${data.c.devisLink}`}>
            {data.c.devisTitle}
          </CustomLink>
        </p>
      </div>
    </div>
  )
}

export default Devis
