import React from "react"
//components
import PhoneFirstFr from "../Info/PhoneFirstFr"
import ContactOnlineFr from "../Info/ContactOnlineFr"
import DevisFr from "../Info/DevisFr"

import PhoneFirstNl from "../Info/PhoneFirstNl"
import ContactOnlineNl from "../Info/ContactOnlineNl"
import DevisNl from "../Info/DevisNl"

const ContactBlock = ({ pageContext }) => {
  if (pageContext.node_locale === "fr" || pageContext.langKey === "fr") {
    return (
      <div className="contactBlock">
        <div className="container">
          <div className="block">
            <div className="mainContact">
              <PhoneFirstFr />
              <ContactOnlineFr />
            </div>
            <DevisFr />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="contactBlock">
        <div className="container">
          <div className="block">
            <div className="mainContact">
              <PhoneFirstNl />
              <ContactOnlineNl />
            </div>
            <DevisNl />
          </div>
        </div>
      </div>
    )
  }
}

export default ContactBlock
