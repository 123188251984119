import React, { useState, useEffect, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
//component
import CustomLink from "../Link/CustomLink"
import ContactBlockHome from "../Info/ContactBlockHome"
//css
import "../../../css/slideShow.css"
//data
const getData = graphql`
  {
    c: allContentfulSlideShow(
      filter: { node_locale: { eq: "nl" } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        node_locale
        slideTitle
        slideButtonTitle
        slideButtonLink
        slideContent {
          slideContent
        }
        slideImage {
          fluid {
            ...GatsbyContentfulFluid_noBase64
            src
          }
        }
      }
    }
  }
`
const SlideShow = ({ pageContext }) => {
  const data = useStaticQuery(getData)
  const dataSlide = data.c.nodes
  const [counter, setCounter] = useState(0)
  const r = useRef(null)
  r.current = { counter, setCounter }

  const nbr = dataSlide.length - 1
  const timer = 7000

  useEffect(() => {
    const id = setInterval(() => {
      if (counter < nbr) {
        r.current.setCounter(r.current.counter + 1)
      } else {
        setCounter(0)
        r.current.setCounter(0)
      }
    }, timer)
    return () => {
      clearInterval(id)
    }
  }, [counter, nbr])
  return (
    <div className="slideShow">
      {dataSlide.map((item, index) => {
        return (
          <div
            key={index}
            className={
              r.current.counter === index ? "slidebox active" : "slidebox"
            }
          >
            <div
              className="slideBg"
              style={{ background: "url(" + item.slideImage.fluid.src + ")" }}
            >
              <div className="slide">
                <div className="container flex">
                  <div className="slideElement ">
                    <h2>{item.slideTitle}</h2>
                    <p>{item.slideContent.slideContent}</p>
                    <CustomLink
                      classname="button"
                      to={`/${item.node_locale}${item.slideButtonLink}`}
                    >
                      {item.slideButtonTitle}
                    </CustomLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
      <ContactBlockHome pageContext={pageContext} />
      <div className="navSlideContainer">
        <div className="container">
          {dataSlide.map((item, index) => {
            return (
              <div
                className={
                  r.current.counter === index
                    ? `active navSlide navSlide${index}`
                    : `navSlide navSlide${index}`
                }
                onClick={() => {
                  setCounter(counter => index)
                }}
                onKeyDown={() => {
                  setCounter(counter => index)
                }}
                role="button"
                tabIndex={0}
                key={index}
              >
                .
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default SlideShow
