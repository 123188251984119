import React from "react"
import { graphql, useStaticQuery } from "gatsby"

//data
const getData = graphql`
  {
    c: allContentfulSocial(filter: { node_locale: { eq: "fr" } }) {
      nodes {
        socialIcon {
          svg {
            content
          }
        }
        socialLink
        socialName
      }
    }
  }
`
const Social = ({ title }) => {
  const data = useStaticQuery(getData)
  return (
    <div className="social">
      {title ? <h2>{title}</h2> : ""}
      <ul>
        {data.c.nodes.map((item, index) => {
          return (
            <li className="socialElement" key={index}>
              <a href={item.socialLink}>
                <div
                  className="icon"
                  dangerouslySetInnerHTML={{
                    __html: item.socialIcon.svg.content,
                  }}
                />
                {item.socialName}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Social
