import React from "react"

const Section = ({ children, classname, flex, container }) => {
  return (
    <div className={`section section-${classname} `}>
      <div
        className={`${flex ? "flex" : ""} ${
          container ? "container" : ""
        } ${classname}`}
      >
        {children}
      </div>
    </div>
  )
}

export default Section
